import React from "react";
import ColoredDropDownSection from "../../../Common/CommonSections/ColoredDropDownSection/DropDownSection";
import { BenefitsSectionContainer } from "./BenefitsSection.style";

const devOpsBenefits = [
    {
        title: "Better quality",
        description: `
            DevOps service includes practices like continuous testing and monitoring, 
            which help to catch defects early in the development cycle. This reduces 
            the risk of defects making it into production and improves overall software quality.
        `,
    },
    {
        title: "Improved collaboration",
        description: `
            DevOps emphasizes collaboration between development and operations teams, which helps 
            to improve communication that results in better work results. This leads to better 
            alignment of goals and priorities, and ultimately, better outcomes.
        `,
    },
    {
        title: "Improved security",
        description: `
            DevOps can help to address your security concerns by including it as part of the development 
            and operations process. This means that security considerations are built in from the start, 
            rather than being an afterthought.
        `,
    },
    {
        title: "Cost savings",
        description: `
            By automating processes and improving efficiency, DevOps as a service can help organizations 
            save money on IT operations. This is particularly important for organizations that are operating 
            on tight budgets.
        `,
    },
    {
        title: "Faster time to market",
        description: `
            By automating processes and reducing the time it takes to deliver software, DevOps helps to get new products 
            and features to market faster. This can give you a competitive advantage and help you stay ahead of the curve.
        `,
    },
    {
        title: "Increased efficiency",
        description: `
            Automation is a key part of DevOps service, and it can help teams work more efficiently by reducing 
            manual tasks and streamlining processes. This frees up time for developers and operations teams 
            to focus on higher-value work.
        `,
    },
    {
        title: "Better scalability",
        description: `
            DevOps practices like infrastructure as code and automated provisioning help organizations to scale 
            their infrastructure more easily and quickly. This is particularly important in cloud environments
            where scalability is key.
        `,
    },
    {
        title: "Improved customer experience",
        description: `
            By delivering software faster and with better quality, DevOps can help you improve the customer experience. 
            This can lead to increased customer satisfaction and loyalty.
        `,
    },
];

function BenefitsSection() {
    return (
        <BenefitsSectionContainer>
            <ColoredDropDownSection
                title="DevOps benefits"
                dropDownOptions={devOpsBenefits}
            />
        </BenefitsSectionContainer>
    );
}

export default BenefitsSection;
