import styled from "styled-components";
import { MOBILE_MIN_WIDTH, TABLET_MAX_WIDTH } from "@constants";

export const BenefitsSectionContainer = styled.div`
    width: 100vw;
    margin-left: -9.4rem;

    @media only screen and (min-width: 70rem) {
        margin-left: calc((100vw - 70rem) / -2);
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        margin-left: -6.25rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        margin-left: -1.5rem;
    }
`;
