import React from "react";
import loadable from "@loadable/component";
import { GLOBAL_APPLICATION_INTERNAL_LINKS } from "@links";
import {
    CATCHUP_SECTION_BLUR_WIDTH,
    CATCHUP_SECTION_BLUR_HEIGHT,
} from "@constants";
import {
    GlobalStyle,
    CatchUpSectionContainer,
} from "./DevOpsEngineering.style";
import BenefitsSection from "./components/BenefitsSection/BenefitsSection";
import DevOpsFAQSection from "./components/FAQSection/FAQSection";
import backgroundImage from "@images/dev-ops/backgroundImage.jpg";
import { 
    FEEDBACK_SECTION_ITEMS,
    SECOND_CATCH_UP_SECTION_DESCRIPTION, 
    SECOND_CATCH_UP_SECTION_TITLE 
} from "./constants";

const TextTitleSection = loadable(() =>
    import("@common/CommonSections/TextTitleSection/TextTitleSection")
);

const FeedbackSection = loadable(() => import("@common/SlideShow/ContentWithFeedback/FeedbacksSection/FeedbacksSection"));

const WhyChooseSection = loadable(() =>
    import("./components/WhyChooseSection/WhyChooseSection")
);

const OffersSection = loadable(() =>
    import("./components/OffersSection/OffersSection")
);

const ProcessSection = loadable(() =>
    import("./components/ProcessSection/ProcessSection")
);

const CircleBLur = loadable(() => import("@common/CircleBlur/CircleBlur"));

const CrawlableSecondCatchUpSection = loadable(() =>
    import("@common/CommonSections/CrawlableSecondCatchUpSection")
);

const LinkButton = loadable(() => import("@common/LinkButton"));

const DevOpsEngineering = () => (
    <div>
        <GlobalStyle />
        <TextTitleSection
            title="DevOps engineering"
            description={`
                    You may have a team of brilliant engineers and talented managers but
                    it won’t be complete without someone who will build and maintain
                    reliable and secure development infrastructure. Namely, DevOps
                    engineer.
                    <br/>
                    Impressit has your back — our skilled DevOps specialists are ready
                    to bring your project onto the next level.
                `}
            backgroundImage={backgroundImage}
        />
        <OffersSection />
        <ProcessSection />
        <FeedbackSection
            feedbacks={FEEDBACK_SECTION_ITEMS}
            title="Our Clients say"
        />
        <WhyChooseSection />
        <BenefitsSection />
        <DevOpsFAQSection />
        <CatchUpSectionContainer>
            <CircleBLur
                width={CATCHUP_SECTION_BLUR_WIDTH}
                height={CATCHUP_SECTION_BLUR_HEIGHT}
            />
            <CrawlableSecondCatchUpSection
                isAnimated  
                title={SECOND_CATCH_UP_SECTION_TITLE}
                description={SECOND_CATCH_UP_SECTION_DESCRIPTION}
            >
                <LinkButton to={GLOBAL_APPLICATION_INTERNAL_LINKS.CONTACT_US}>
                    Contact us
                </LinkButton>
            </CrawlableSecondCatchUpSection>
        </CatchUpSectionContainer>
    </div>
);

export default DevOpsEngineering;

